//mainPage.js
import React, { useEffect, useState, useMemo, useCallback } from "react";
import axios from "axios";
import {
  Grid,
  CardActionArea,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import ExploreIcon from "@mui/icons-material/Explore";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DishesToTry from "./DishesToTry";
import FlippingCard from "../../components/FlippingCard";
import "../../styles/grid.css"; // Import the grid CSS
import "../../styles/masonry.css";
import Layout from "../../components/layout/Layout";
import Masonry from "react-masonry-css";
import PopupZurich from "./PopupZurich";
import axiosInstance from "../../axiosInstance";
import { Helmet } from "react-helmet";
import ChefIcon from "../../assets/chef";

const imageFood =
  "https://s3.eu-north-1.amazonaws.com/zurich.project.save/main-img/1739959436647.webp";
const imageBeverages =
  "https://s3.eu-north-1.amazonaws.com/zurich.project.save/main-img/1739959424046.webp";
const imageClubs =
  "https://s3.eu-north-1.amazonaws.com/zurich.project.save/main-img/1739959443621.webp";
const imageChef =
  "https://s3.eu-north-1.amazonaws.com/zurich.project.save/main-img/1739959430241.webp";

// import imageFood from "../../assets/food2.jpg";
// import imageBeverages from "../../assets/bar1.jpg";
// import imageClubs from "../../assets/name4.jpg";
// import imageChef from "../../assets/chef1.jpg";
const MainPage = () => {
  const navigate = useNavigate();
  const [dishesToTry, setDishesToTry] = useState([]);
  const [popupsData, setPopupsData] = useState([]);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(true);

  // const categories = useMemo(
  //   () => [
  //     { name: "Food", path: "/restaurants", Icon: RestaurantIcon },
  //     { name: "Beverages", path: "/bars", Icon: LocalBarIcon },
  //     { name: "Nightlife", path: "/clubs", Icon: MusicNoteIcon },
  //     // { name: 'Explore', path: '/events', Icon: ExploreIcon },
  //     { name: "Meet the Chef", path: "/meet-the-chef", Icon: ChefIcon }, // New card for "Meet the Chef"
  //   ],
  //   []
  // );

  const handleCardClick = (path) => {
    navigate(path);
  };

  // Optimized data fetching with proper AbortController handling
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      if (!isMounted) return;

      setIsLoading(true);
      try {
        console.log("Fetching data from APIs...");

        // Make sure both axios and axiosInstance properly handle the signal
        const dishesPromise = axios.get(`${baseUrl}/api/dishes`, {
          signal,
          // Add cache headers
          headers: {
            "Cache-Control": "max-age=300",
          },
        });

        // For axiosInstance, we need to handle the signal differently
        // because some versions of axios have issues with AbortController
        const popupsPromise = axiosInstance.get("/api/popups", {
          // Some axios versions need this format
          cancelToken: axios.CancelToken
            ? new axios.CancelToken((cancel) => {
                signal.addEventListener("abort", () =>
                  cancel("Operation canceled by the user")
                );
              })
            : undefined,
          signal: signal, // Modern axios versions use this
          // Add cache headers
          headers: {
            "Cache-Control": "max-age=300",
          },
        });

        // Wait for both promises to resolve
        const [dishesResponse, popupsResponse] = await Promise.all([
          dishesPromise,
          popupsPromise,
        ]);

        // Only update state if component is still mounted
        if (isMounted) {
          console.log("Data fetched successfully");

          // Process dishes data
          const validDishes = dishesResponse.data.filter(
            (dish) => dish.restaurantId
          );
          setDishesToTry(validDishes);

          // Process popups data
          setPopupsData(popupsResponse.data);
        }
      } catch (error) {
        // Check for both AbortError and CanceledError
        if (
          error.name !== "AbortError" &&
          error.name !== "CanceledError" &&
          error.code !== "ERR_CANCELED" &&
          isMounted
        ) {
          console.error("Error fetching data:", error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    // Execute fetch
    fetchData();

    // Cleanup function to abort fetch requests if component unmounts
    return () => {
      isMounted = false;
      controller.abort();
      console.log("Fetch aborted due to component unmount");
    };
  }, [baseUrl]); // Only include baseUrl in dependencies

  const getStyleClass = (index) => {
    const styleClasses = [
      "style-0",
      "style-1",
      "style-2",
      "style-3",
      "style-4",
      "style-5",
    ];
    return styleClasses[index % styleClasses.length];
  };

  const foodSectionStyles = {
    container: {
      height: { xs: "auto", md: "85vh" },
      display: "flex",
      alignItems: "center",
      flexDirection: { xs: "column-reverse", md: "row" },
      textAlign: { xs: "", md: "left" },
      padding: { xs: 3, md: 0 },
    },
    textContent: {
      padding: { xs: 2, md: 8 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    image: {
      width: "100%",
      maxHeight: "500px",
      objectFit: "cover",
      borderRadius: "20px",
    },
  };

  const beveragesSectionStyles = {
    container: {
      height: { xs: "auto", md: "85vh" },
      display: "flex",
      alignItems: "center",
      flexDirection: { xs: "column-reverse", md: "row-reverse" },
      textAlign: { xs: "center", md: "left" },
      padding: { xs: 4, md: 0 },
    },
    textContent: {
      padding: { xs: 3, md: 8 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "100%",
      maxHeight: "500px",
      objectFit: "cover",
      borderRadius: "20px",
    },
  };

  const clubsSectionStyles = {
    container: {
      height: { xs: "auto", md: "85vh" },
      display: "flex",
      alignItems: "center",
      flexDirection: { xs: "column-reverse", md: "row" },
      textAlign: { xs: "center", md: "left" },
      padding: { xs: 4, md: 0 },
    },
    textContent: {
      padding: { xs: 3, md: 8 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "100%",
      maxHeight: "500px",
      objectFit: "cover",
      borderRadius: "20px",
    },
  };

  const meetTheChefSectionStyles = {
    container: {
      height: { xs: "auto", md: "85vh" },
      display: "flex",
      alignItems: "center",
      flexDirection: { xs: "column-reverse", md: "row-reverse" },
      textAlign: { xs: "center", md: "left" },
      padding: { xs: 4, md: 0 },
    },
    textContent: {
      padding: { xs: 3, md: 8 },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      width: "100%",
      maxHeight: "500px",
      objectFit: "cover",
      borderRadius: "20px",
    },
  };

  return (
    <Layout>
      <Helmet>
        <title>
          Best Restaurants in Zurich - Top Dining, Bars & Clubs | MyZurich
        </title>
        <meta
          name="description"
          content="Find the top restaurants in Zurich, bars, and nightclubs in Zurich. Explore Swiss, Italian, and Mediterranean cuisine, plus pop-ups and must-try dishes!"
        />
        <meta
          name="keywords"
          content="Zurich restaurants, best food in Zurich, Zurich bars, Zurich nightlife, things to do in Zurich"
        />
        <meta
          property="og:title"
          content="Best Restaurants in Zurich - Top Dining & Nightlife | MyZurich"
        />
        <meta
          property="og:description"
          content="Explore the top places to eat and drink in Zurich. Handpicked recommendations for food lovers and nightlife seekers."
        />
        <meta
          property="og:image"
          content="https://www.myswisss.ch/myswisss.png"
        />
        <meta property="og:url" content="https://www.myswisss.ch/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://www.myswisss.ch/" />

        {/* ✅ Structured Data for SEO Optimization */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "MyZurich",
            url: "https://www.myswisss.ch/",
            description:
              "Explore Zurich's top-rated restaurants, bars, and nightlife. Find must-try dishes and local events!",
            image: "https://www.myswisss.ch/myswisss.png",
            publisher: {
              "@type": "Organization",
              name: "MyZurich",
              logo: "https://www.myswisss.ch/myswisss.png",
            },
            potentialAction: {
              "@type": "SearchAction",
              target: "https://www.myswisss.ch/?search={query}",
              "query-input": "required name=query",
            },
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ItemList",
            name: "Dishes to Try in Zurich",
            description:
              "A curated list of must-try dishes from the best restaurants in Zurich.",
            itemListElement: dishesToTry.map((dish, index) => ({
              "@type": "MenuItem",
              position: index + 1,
              name: dish.name,
              image: dish.image || "https://www.myswisss.ch/myswisss.png",
              description: dish.description,
              servesCuisine: dish.restaurantId?.cuisine || "Various",
              provider: {
                "@type": "Restaurant",
                name: dish.restaurantId?.name || "Unknown Restaurant",
                address: {
                  "@type": "PostalAddress",
                  streetAddress:
                    dish.restaurantId?.location?.address || "Zurich",
                  addressLocality: "Zurich",
                  addressCountry: "CH",
                },
              },
            })),
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Event",
            name: popupsData.length > 0 ? popupsData[0].name : "Unnamed Event",
            description:
              popupsData.length > 0
                ? popupsData[0].description
                : "No description available",
            startDate:
              popupsData.length > 0
                ? popupsData[0].startDate || "2025-03-01T21:00:00"
                : "2025-03-01T18:00:00",
            endDate:
              popupsData.length > 0
                ? popupsData[0].endDate || "2025-03-01T21:00:00"
                : "2025-03-01T21:00:00", // Default end date

            location: {
              "@type": "Place",
              name:
                popupsData.length > 0 ? popupsData[0].name : "Unknown Location",
              address: {
                "@type": "PostalAddress",
                streetAddress:
                  popupsData.length > 0
                    ? popupsData[0].location?.address
                    : "Seefeldstrasse 123, 8008 Zurich, Switzerland",
                addressLocality: "Zurich",
                addressCountry: "CH",
              },
              geo: {
                "@type": "GeoCoordinates",
                latitude:
                  popupsData.length > 0
                    ? popupsData[0].location?.latitude
                    : 47.3554,
                longitude:
                  popupsData.length > 0
                    ? popupsData[0].location?.longitude
                    : 8.5512,
              },
            },
            organizer: {
              "@type": "Organization",
              name: "Zurich Events",
              url: "https://www.myswisss.ch/",
            },
            performer: {
              "@type": "PerformingGroup",
              name:
                popupsData.length > 0
                  ? popupsData[0].performer || "Guest Speaker"
                  : "Guest Speaker",
            },
            image:
              popupsData.length > 0
                ? popupsData[0].images?.[0]
                : "https://www.myswisss.ch/myswisss.png",
            eventAttendanceMode:
              "https://schema.org/OfflineEventAttendanceMode",
            eventStatus: "https://schema.org/EventScheduled",
            offers: {
              "@type": "Offer",
              price: "0", // Fixed pricing issue
              priceCurrency: "CHF",
              validFrom:
                popupsData.length > 0
                  ? popupsData[0].validFrom || "2025-02-20T12:00:00"
                  : "2025-02-20T12:00:00",
              availability:
                popupsData.length > 0
                  ? popupsData[0].availability || "https://schema.org/InStock"
                  : "https://schema.org/InStock",
              url:
                popupsData.length > 0
                  ? popupsData[0].moreInfo
                  : "https://www.myswisss.ch/",
            },
          })}
        </script>
      </Helmet>

      {/* ✅ Optimized Headings for SEO (Hidden from Users) */}
      <Typography
        component="h1"
        variant="h4"
        sx={{
          position: "absolute", // Remove from normal flow
          width: "1px", // Make it tiny
          height: "1px", // Make it tiny
          overflow: "hidden", // Prevent content from appearing
          clip: "rect(0 0 0 0)", // Hide visually
          whiteSpace: "nowrap", // Prevent multi-line visibility
        }}
      >
        {" "}
        Best Restaurants, Bars and Nightlife in Zurich
      </Typography>

      <Typography
        component="h2"
        variant="h5"
        sx={{
          position: "absolute", // Remove from normal flow
          width: "1px", // Make it tiny
          height: "1px", // Make it tiny
          overflow: "hidden", // Prevent content from appearing
          clip: "rect(0 0 0 0)", // Hide visually
          whiteSpace: "nowrap", // Prevent multi-line visibility
        }}
      >
        {" "}
        Discover Zurich's Top Places to Eat and Drink
      </Typography>
      <Typography
        component="h2"
        variant="h5"
        sx={{
          position: "absolute", // Remove from normal flow
          width: "1px", // Make it tiny
          height: "1px", // Make it tiny
          overflow: "hidden", // Prevent content from appearing
          clip: "rect(0 0 0 0)", // Hide visually
          whiteSpace: "nowrap", // Prevent multi-line visibility
        }}
      >
        {" "}
        What are the best restaurants in Zurich?
      </Typography>

      {/* Neon Wave Typography Animation
        <Grid item xs={12}>
          <Typography
            variant="h1"
            gutterBottom
            sx={{
              textAlign: "center",
              mt: 0,
              fontWeight: 700,
              fontSize: "clamp(3.5rem, 8vw, 5.5rem)", // Keep the larger size but ensure responsiveness
              wordSpacing: { xs: "0.3rem", sm: "0.5rem" },
              padding: { xs: "0 10px" }, // Padding to prevent overflow
              wordBreak: "break-word", // Allow long words to break into a new line
              hyphens: "auto", // Enable hyphenation if needed
              lineHeight: 1.2, // Adjust line height to tighten text spacing
              letterSpacing: "0.02em", // Slight letter spacing for readability
              backgroundImage:
                "linear-gradient(45deg, #ffea0c, #282c34, #ffea0c)",
              backgroundSize: "200% 200%",
              backgroundClip: "text",
              color: "transparent",
              animation: "colorWave 5s ease-in-out infinite",
              "@keyframes colorWave": {
                "0%": { backgroundPosition: "200% 0" },
                "50%": { backgroundPosition: "0% 100%" },
                "100%": { backgroundPosition: "200% 0" },
              },
            }}
          >
            Find The Best Places In Zurich
          </Typography>
        </Grid> */}

      {/* Food Section */}
      <Grid container sx={foodSectionStyles.container}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ...foodSectionStyles.textContent,
            display: { xs: "none", sm: "none", md: "block" }, // Show only on large screens
          }}
        >
          {" "}
          <Typography
            component="h2"
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: "3.5rem",
              fontFamily: `"Neue Helvetica", Arial, sans-serif`, // ✅ Force it here
            }}
          >
            Discover Amazing Food
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555", mb: 3, fontSize: "1.3rem" }}
          >
            Explore the best restaurants in Zurich with hand-picked dishes that
            you need to try.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative", // Make the container relative
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea onClick={() => navigate("/restaurants")}>
            <img
              src={imageFood}
              alt="Best restaurants in Zurich"
              style={foodSectionStyles.image}
              loading="lazy"
            />
          </CardActionArea>

          <Button
            variant="text"
            sx={{
              position: "absolute", // Position the button absolutely
              bottom: "20px", // Adjust as needed
              left: "50%", // Center horizontally
              transform: "translateX(-50%)", // Center horizontally
              backgroundColor: "trasparent",
              color: "white", // Set font color to black
              fontWeight: "bold",
              padding: { xs: "8px 16px", md: "12px 24px" }, // Responsive padding
              fontSize: { xs: "1.35rem", md: "2rem" }, // Responsive font size
            }}
            onClick={() => navigate("/restaurants")}
          >
            View Restaurants
          </Button>
        </Grid>
      </Grid>

      {/* Beverages Section */}
      <Grid container sx={beveragesSectionStyles.container}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ...beveragesSectionStyles.textContent,
            display: { xs: "none", sm: "none", md: "block" }, // Show only on large screens
          }}
        >
          {" "}
          <Typography
            component="h2"
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: "3.5rem",
              fontFamily: `"Neue Helvetica", Arial, sans-serif`, // ✅ Force it here
            }}
          >
            Enjoy Refreshing Beverages
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555", mb: 3, fontSize: "1.3rem" }}
          >
            Discover the best bars and coffee Shops in Zurich offering a variety
            of drinks.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea onClick={() => navigate("/bars")}>
            <img
              src={imageBeverages}
              alt="Best Bars in Zurich"
              style={beveragesSectionStyles.image}
              loading="lazy"
            />
          </CardActionArea>

          <Button
            variant="text"
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              color: "#fffefe",
              fontWeight: "bold",
              padding: { xs: "8px 16px", md: "12px 24px" },
              fontSize: { xs: "1.35rem", md: "2rem" },
            }}
            onClick={() => navigate("/bars")}
          >
            Cocktail & Coffee
          </Button>
        </Grid>
      </Grid>

      {/* Clubs Section */}
      <Grid container sx={clubsSectionStyles.container}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ...clubsSectionStyles.textContent,
            display: { xs: "none", sm: "none", md: "block" }, // Show only on large screens
          }}
        >
          {" "}
          <Typography
            component="h2"
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: "3.5rem",
              fontFamily: `"Neue Helvetica", Arial, sans-serif`, // ✅ Force it here
            }}
          >
            {" "}
            Experience the Nightlife
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555", mb: 3, fontSize: "1.3rem" }}
          >
            Enjoy the vibrant nightlife in Zurich with top clubs and events.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea onClick={() => navigate("/clubs")}>
            <img
              src={imageClubs}
              alt="Best nightlife in Zurich"
              style={clubsSectionStyles.image}
              loading="lazy"
            />
          </CardActionArea>

          <Button
            variant="text"
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              color: "#fffefe",
              fontWeight: "bold",
              padding: { xs: "8px 16px", md: "12px 24px" },
              fontSize: { xs: "1.35rem", md: "2rem" },
            }}
            onClick={() => navigate("/clubs")}
          >
            Explore Clubs
          </Button>
        </Grid>
      </Grid>

      {/* Meet the Chef Section */}
      <Grid container sx={meetTheChefSectionStyles.container}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            ...meetTheChefSectionStyles.textContent,
            display: { xs: "none", sm: "none", md: "block" }, // Show only on large screens
          }}
        >
          {" "}
          <Typography
            component="h2"
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: "3.5rem",
              fontFamily: `"Neue Helvetica", Arial, sans-serif`, // ✅ Force it here
            }}
          >
            {" "}
            Meet The Creator
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#555", mb: 3, fontSize: "1.3rem" }}
          >
            Get to know the culinary artists behind Zurich's finest creations.
            Discover their stories and inspirations.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardActionArea onClick={() => navigate("/meet-the-chef")}>
            <img
              src={imageChef}
              alt="Meet the Chef in Zurich"
              style={meetTheChefSectionStyles.image}
              loading="lazy"
            />
          </CardActionArea>
          <Button
            variant="text"
            sx={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              color: "#fff",
              fontWeight: "bold",
              padding: { xs: "8px 16px", md: "12px 24px" },
              fontSize: { xs: "1.35rem", md: "2rem" },
            }}
            onClick={() => navigate("/meet-the-chef")}
          >
            Meet the Creator
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h4"
        gutterBottom
        className="headline-yellow"
        sx={{
          mt: { xs: 8, sm: 10, md: 12 }, // Adjust margin-top based on screen size
          mb: 3, // Bottom margin
          textAlign: "center",
          fontWeight: 700,
        }}
      >
        You Need to Try
      </Typography>
      <Grid container spacing={4}>
        <DishesToTry dishes={dishesToTry} />
      </Grid>

      {/* Add PopupZurich Section */}
      <Typography
        variant="h4"
        gutterBottom
        className="headline-yellow"
        sx={{ mt: 5, fontWeight: 700, textAlign: "center" }} // Center the heading
      >
        Pop-ups Zurich
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <PopupZurich popups={popupsData} />{" "}
      </Box>
    </Layout>
  );
};

export default React.memo(MainPage); // Use memo to prevent unnecessary re-renders
