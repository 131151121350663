import React, { useEffect } from "react";
import { Box } from "@mui/material";

const SponsorsBar = () => {
  // Inject keyframes for scrolling animation
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  // Scrolling animation styles for the sponsors bar content
  const barContentStyle = {
    display: "inline-flex",
    whiteSpace: "nowrap",
    overflow: "hidden",
    animation: "scroll 180s linear infinite", // Slowed down to accommodate more items
  };

  // Style for each sponsor link
  const itemStyle = {
    minWidth: "max-content",
    padding: "0 50px",
    fontSize: "1.5rem", // Adjust the size of the sponsor text
    color: "rgba(0, 0, 0, 0.6)",
    textDecoration: "none",
    fontWeight: "bold",
    background:
      "repeating-linear-gradient(transparent, transparent, 17px, #ffea0c, #ffea0c, 17px, #ffea0c, #ffea0c, 29px, transparent 29px, transparent 50px)", // Yellow line effect
    backgroundSize: "100% 100%",
    fontFamily: "'Open Sans Condensed', Tahoma, Verdana, sans-serif",
    letterSpacing: "0.01em", // Keeping letter spacing for better readability
    display: "inline-block", // Ensure the background applies correctly around text
  };

  return (
    <Box sx={barContentStyle}>
      {/* Sponsor items, repeated twice to create an infinite loop effect */}
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor */}
      </a>

      {/* Duplicate the sponsor items to create a seamless loop */}
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
      <a href="https://example.com" style={itemStyle}>
        {/* sponsor{" "} */}
      </a>
    </Box>
  );
};

export default SponsorsBar;
