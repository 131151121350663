const ChefIcon = () => (
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 400 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      {" "}
      <path
        d="M89.5817 120C58.6934 91.5024 74.0344 44.757 110.302 41.133C127.029 39.4629 149.113 54.0714 159.999 54.0714C194.568 54.0714 228.221 27.2561 260.493 54.0714C279.192 69.6052 269.699 99.5044 257.006 107.036"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M90 117C142.667 111.566 197.696 95.755 250 112.449"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M93.0559 139C92.8995 145.073 93.028 151.088 94 157"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M97 155.173C145.65 132.936 205.18 134.027 254 156"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M253 131C253.464 138.692 254 146.28 254 154"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M93.7931 186.199C85.6744 187.218 87.5015 211.695 103.941 211.695C103.941 305 263.001 295 247.277 202.221C256 202.221 265.677 181.04 253.5 179"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M160 206C160.457 203.005 160.774 199.981 161 197"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M196 206C196 203.545 196 200.005 196 197"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M186 245C179.911 247.785 172.5 249.957 168 245.225"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M38 360C47.0818 317.424 79.2813 287.286 125.151 287.286C125.88 287.286 154.503 315.535 180.414 313.586C203.33 311.863 216.097 294.682 235.003 287.286C263.137 276.278 307 338.995 307 358.456"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M350 159C338.607 183.953 286.988 272.605 292.396 306"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M353.983 156C361.109 192.062 371.983 282.642 333 308"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M328 304C318.437 300.114 309.042 295.03 299 293"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M294.975 308C285.934 328.2 276.314 343.303 299 320.79"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M311 320C308.736 331.403 297.744 346.679 290 331.889"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M211 336.863C210.815 349.971 203 348.348 203 336"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
      <path
        d="M146 351.449C135.34 353.573 138.871 349.231 143.412 344"
        stroke="currentColor"
        stroke-opacity="0.9"
        stroke-width="16"
        stroke-linecap="round"
        stroke-linejoin="round"
      />{" "}
    </g>
  </svg>
);

export default ChefIcon;
