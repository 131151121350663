// src/components/Layout.js
// Layout.js
import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import Navbar from "./Navbar"; // Import the Navbar component
import ScrollToTop from "./ScrollToTop";
import SponsorsBarWrapper from "./SponsorsBarWrapper";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import BackButton from "./BackButton";

/**
 * Layout component for consistent structure across all pages.
 * Includes Navbar, SEO meta tags, and a structured Container for the content.
 *
 * @param {object} props - The component's props.
 * @param {ReactNode} props.children - The main content to be wrapped.
 * @param {string} props.title - The title of the page (optional).
 * @param {string} props.description - The meta description for SEO (optional).
 */

const Layout = React.memo(({ children, title, description }) => {
  const pageTitle = title
    ? `${title} | MyZurich`
    : "MyZurich - Discover Zurich";
  const pageDescription =
    description ||
    "Explore Zurich's best restaurants, bars, and events. Find hidden gems and top places to visit.";
  const ogImage = "https://www.myswisss.ch/myswisss.png";

  // Safe way to get current URL that works with SSR
  const [currentUrl, setCurrentUrl] = useState("https://www.myswisss.ch/");

  useEffect(() => {
    // Only access window object after component mounts (client-side)
    setCurrentUrl(window.location.href);
  }, []);

  return (
    <>
      <Helmet>
        <html lang="en" /> {/* Dynamically set language for SEO */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content="Zurich restaurants, best bars Zurich, nightlife, fine dining, food guide Zurich, top places to eat"
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <link rel="canonical" href={currentUrl} />
      </Helmet>{" "}
      <Navbar aria-label="Main Navigation" />
      {/* <BackButton /> Add the BackButton below the Navbar */}
      <Box sx={{ bgcolor: "transparent", pb: "1rem" }}>
        {" "}
        {/* Add top margin and background color */}
        <Container maxWidth="lg">
          {children} {/* Render the main content here */}
        </Container>
      </Box>
      <ScrollToTop /> {/* Add the ScrollToTop component */}
      <SponsorsBarWrapper />
      <Footer />
    </>
  );
});

export default Layout;
