import React from "react";
import { Box, Typography, Container, Link } from "@mui/material";

const Footer = () => {
  return (
    <Box
      sx={{
        padding: "1.5rem 0",
        textAlign: "center",
        position: "relative",
        bottom: 0,
        width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "row",
            justifyContent: "center",
            gap: "20px",
            marginTop: "1rem",
          }}
        >
          <Link href="/privacy-policy" variant="body2" underline="none">
            | Privacy Policy |
          </Link>
          <br />
          <Link href="/terms-of-service" variant="body2" underline="none">
            | Terms of Service |
          </Link>
          <Link href="/legal-disclaimer" variant="body2" underline="none">
            | Legal Disclaimer |
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
