// SponsorsBarWrapper.js
import React from "react";
import { Box } from "@mui/material";
import SponsorsBar from "./SponsorsBar";

const SponsorsBarWrapper = () => {
  // Footer wrapper style to keep it always fixed at the bottom of the screen
  const wrapperStyle = {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "transparent", // Solid black background
    zIndex: 9999, // Keeps it above other elements
    overflow: "hidden", // Ensures the content scrolls inside
  };

  return (
    <Box sx={wrapperStyle}>
      <SponsorsBar />
    </Box>
  );
};

export default SponsorsBarWrapper;
