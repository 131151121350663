//DishesToTry.js
import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Card,
  CardMedia,
  Typography,
  Box,
  Button,
  styled,
  IconButton,
  Container,
  keyframes,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Helmet } from "react-helmet";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const NavigationButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.black,
  backgroundColor: "transparent",
  boxShadow: theme.shadows[2],
  "&:hover": {
    backgroundColor: theme.palette.grey[300],
  },
  zIndex: 999,
  padding: "8px",
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  height: "4px",
  width: "60%",
  backgroundColor: theme.palette.grey[300],
  borderRadius: "5px",
  position: "relative",
  margin: "0 50px",
}));

const ProgressBar = styled(Box)(({ theme }) => ({
  height: "4px",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "5px",
  transition: "width 0.3s ease-in-out",
}));

const ArrowAndProgressWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "20px",
});

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: "rgb(0 0 0 / 87%)",
  marginBottom: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "15px",
  boxShadow: theme.shadows[3],
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  "&:hover": {
    boxShadow: theme.shadows[6],
  },
  marginBottom: "16px",
  margin: "0 10px",
  position: "relative",
  paddingBottom: "50px",
}));

const loadAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const TextButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontWeight: "bold",
  backgroundColor: "transparent",
  color: "#797B84",
  "&:hover": {
    backgroundColor: "transparent",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: "0",
      height: "2px",
      backgroundColor: "#797B84",
      animation: `${loadAnimation} 0.5s forwards`,
    },
  },
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: "20px",
  padding: 0,
  minWidth: "auto",
}));

// Custom styled dot component
// Style the dot
const StyledDot = styled("div")(({ active }) => ({
  width: active ? "12px" : "10px",
  height: active ? "12px" : "10px",
  borderRadius: "50%",
  backgroundColor: active ? "#000" : "#ccc",
  margin: "0 5px",
  cursor: "pointer",
}));

// Define the CustomDot component
const CustomDot = ({ onClick, active }) => {
  return <StyledDot onClick={onClick} active={active} />;
};

const DishesToTry = React.memo(({ dishes }) => {
  const navigate = useNavigate();
  const [activeSlide, setActiveSlide] = useState(0);
  const carouselRef = useRef(null);

  // console.log("Dishes:", dishes); // Debug log

  if (!Array.isArray(dishes) || dishes.length === 0) {
    return <Typography variant="h6">No dishes available</Typography>;
  }

  const totalItems = dishes.length;
  const progressBarWidth = ((activeSlide + 1) / totalItems) * 100;

  const handleLeftArrowClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
      const currentIndex = (activeSlide - 1 + totalItems) % totalItems;
      setActiveSlide(currentIndex);
    }
  };

  const handleRightArrowClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      const currentIndex = (activeSlide + 1) % totalItems;
      setActiveSlide(currentIndex);
    }
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    setActiveSlide(newIndex);
  };

  return (
    <Container sx={{ py: 4 }}>
      {/* ✅ Hidden H1 for SEO (Not Visible on the Page) */}
      <Typography
        component="h1"
        variant="h4"
        sx={{
          position: "absolute", // Remove from normal flow
          width: "1px", // Make it tiny
          height: "1px", // Make it tiny
          overflow: "hidden", // Prevent content from appearing
          clip: "rect(0 0 0 0)", // Hide visually
          whiteSpace: "nowrap", // Prevent multi-line visibility
        }}
      >
        {" "}
        Best Dishes to Try in Zurich - Top Swiss Cuisine
      </Typography>

      <Carousel
        ref={carouselRef}
        responsive={responsive}
        swipeable
        draggable={false}
        showDots={false}
        infinite
        autoPlay={false}
        arrows={false}
        beforeChange={handleBeforeChange}
        containerClass="carousel-container"
        transitionDuration={500} // in milliseconds, adjust as needed
      >
        {dishes.map((dish, i) => (
          <Box key={dish._id || i} sx={{ px: 2, pb: 5 }}>
            <StyledCard
              onClick={() =>
                dish.restaurantId
                  ? navigate(`/restaurant/${dish.restaurantId._id}`)
                  : null
              } // Only navigate if restaurantId exists
              sx={{ cursor: dish.restaurantId ? "pointer" : "default" }} // Change cursor based on availability of restaurantId
            >
              {" "}
              <CardMedia
                component="img"
                image={
                  dish.image
                    ? dish.image
                    : "https://www.myswisss.ch/myswisss.png"
                }
                alt={`Dish: ${dish.name} a specialty from ${
                  dish.restaurantId?.name || "one of Zurich's top restaurants"
                }`}
                loading="lazy" // Add this attribute
                sx={{
                  borderRadius: "15px 15px 0 0",
                  objectFit: "contain", // Or 'contain', depending on your preference
                  // aspectRatio: '4/', // Or any ratio you prefer, this enforces the aspect ratio
                  width: "100%",
                  height: "auto",
                }}
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ p: 2, fontWeight: 700 }}
              >
                {dish.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ p: 2 }}>
                {dish.description}
              </Typography>
              {dish.restaurantId ? (
                <TextButton
                  onClick={() =>
                    navigate(`/restaurant/${dish.restaurantId._id}`)
                  }
                >
                  Book Now
                </TextButton>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: "center", mt: 1 }}
                >
                  No associated restaurant
                </Typography>
              )}
            </StyledCard>
          </Box>
        ))}
      </Carousel>
    </Container>
  );
});

export default DishesToTry;
