import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Button,
  Box,
  styled,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // For SEO

const PopupContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center", // Center horizontally
  margin: "0 auto", // Center container itself
  width: "100%", // Use full width
  maxWidth: "1200px", // Set a max-width for large screens
  padding: "1rem", // Add padding for spacing
}));

const PopupCard = styled(Card)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: theme.shadows[3], // Add shadow for the card
  borderRadius: "10px", // Rounded corners
  transition: "box-shadow 0.3s ease, transform 0.3s ease",
  "&:hover": {
    boxShadow: theme.shadows[6], // More prominent shadow on hover
    transform: "scale(1.02)", // Slight zoom effect
  },
}));

const PopupItem = styled(Box)(({ theme, isHalfWidth }) => ({
  flex: isHalfWidth ? "0 0 50%" : "0 0 33.3333%",
  maxWidth: isHalfWidth ? "50%" : "33.3333%",
  padding: "1rem",
  boxSizing: "border-box",
  marginBottom: isHalfWidth ? "2rem" : "0",
  [theme.breakpoints.down("sm")]: {
    flex: "0 0 100%",
    maxWidth: "100%",
    marginBottom: "1rem",
  },
}));

const PopupImage = styled(CardMedia)({
  objectFit: "cover",
  borderRadius: "10px 10px 0 0", // Rounded corners at the top
  width: "100%",
});

const LearnMoreButton = styled(Button)({
  marginTop: "0.0rem",
  color: "rgba(0, 0, 0, 0.6)",
  textTransform: "none",
  padding: "0px",
});

const TruncatedTypography = styled(Typography)({
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 3, // Limits the text to 3 lines
});

const PopupZurich = ({ popups }) => {
  const navigate = useNavigate();

  return (
    <PopupContainer>
      {/* ✅ Hidden H1 for SEO (Not Visible on the Page) */}
      <Typography
        component="h1"
        variant="h4"
        sx={{
          position: "absolute", // Remove from normal flow
          width: "1px", // Make it tiny
          height: "1px", // Make it tiny
          overflow: "hidden", // Prevent content from appearing
          clip: "rect(0 0 0 0)", // Hide visually
          whiteSpace: "nowrap", // Prevent multi-line visibility
        }}
      >
        {" "}
        Best Pop-Up Events in Zurich - Unique Experiences
      </Typography>

      {popups.map((popup, index) => {
        const isHalfWidth = index % 5 < 2;
        return (
          <PopupItem key={popup._id} isHalfWidth={isHalfWidth}>
            <PopupCard>
              <CardActionArea onClick={() => navigate(`/popup/${popup._id}`)}>
                <PopupImage
                  component="img"
                  image={popup.images[0] || "default-image-url"}
                  alt={`Popup event: ${popup.name}`} // Descriptive alt text
                  loading="lazy"
                />
                <CardContent>
                  <Typography variant="h6" fontWeight={700} gutterBottom>
                    {popup.name}
                  </Typography>
                  <TruncatedTypography variant="body2" color="textSecondary">
                    {popup.description}
                  </TruncatedTypography>
                  <LearnMoreButton
                    onClick={() => navigate(`/popup/${popup._id}`)}
                    style={{
                      textTransform: "none",
                      fontWeight: "bold",
                      padding: "0.5rem 1rem",
                      marginTop: "0.5rem",
                      backgroundColor: "transparent",
                      fontSize: "0.9rem",
                      transition: "color 0.3s ease",
                    }}
                    onMouseOver={(e) => (e.target.style.color = "#d6cd6d")}
                    onMouseOut={(e) => (e.target.style.color = "grey")}
                  >
                    Learn More
                  </LearnMoreButton>
                </CardContent>
              </CardActionArea>
            </PopupCard>
          </PopupItem>
        );
      })}
    </PopupContainer>
  );
};

export default PopupZurich;
