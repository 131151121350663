//Navbar.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Modal,
  Box,
  List,
  ListItem,
  ListItemText,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, Link } from "react-router-dom";
import noisyTexture from "../../assets/noisy-texture2.png"; // Import the noise texture
import { Helmet } from "react-helmet";

const StyledHamburger = styled("div")(({ theme, open }) => ({
  cursor: "pointer",
  opacity: open ? 0 : 1,
  width: "2rem",
  height: "2px",
  backgroundColor: open ? "transparent" : "black",
  position: "relative",
  transform: open ? "translateX(-50px)" : "none",
  transition: "all 0.3s ease-in-out",
  "&::before, &::after": {
    content: '""',
    backgroundColor: "black",
    position: "absolute",
    left: 0,
    width: "100%",
    height: "100%",
    transition: "all 0.3s ease-in-out",
  },
  "&::before": {
    top: "-0.7rem",
    transform: open ? "rotate(45deg)" : "none",
  },
  "&::after": {
    top: "0.7rem",
    transform: open ? "rotate(-45deg)" : "none",
  },
  "&:hover": {
    backgroundColor: "transparent",
    opacity: 0,
  },
}));

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: "#9e9e9e",
}));

const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(4),
  "& .MuiListItem-root": {
    padding: theme.spacing(2, 0),
  },
  "& .MuiListItemText-primary": {
    fontFamily: "Monospace",
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
  },
}));

const Navbar = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setModalOpen(!modalOpen);
  };

  const handleNavigate = (path) => {
    setModalOpen(false);
    navigate(path);
  };

  return (
    <>
      <AppBar
        position="fixed"
        style={{
          background: "transparent",
          boxShadow: "none",
          color: "black",
          marginBottom: "32px",
        }}
      >
        <Container maxWidth="lg">
          <Toolbar style={{ justifyContent: "space-between", padding: 0 }}>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              to="/"
              className="headline-yellow navbar-title"
              sx={{
                textDecoration: "none",
                display: "inline-block", // Ensures the gradient wraps around the text properly
                color: "inherit",
                padding: "5px 10px", // Optional: Adds some spacing around the text
              }}
            >
              My_Zurich
            </Typography>

            <IconButton edge="end" color="inherit" onClick={handleMenuToggle}>
              <StyledHamburger open={modalOpen} />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <br></br>
      {/* <Toolbar /> Adds space below the navbar */}
      <Modal
        open={modalOpen}
        onClose={handleMenuToggle}
        aria-labelledby="menu-modal-title"
        aria-describedby="menu-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            bgcolor: "rgba(0, 0, 0, 0.8)", // Semi-transparent black
            zIndex: 1301,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${noisyTexture})`, // Use the imported noise texture
            backgroundSize: "auto",
            animation: "noiseAnimation 1s steps(10) infinite",
          }}
        >
          <StyledCloseButton onClick={handleMenuToggle}>
            <CloseIcon /> {/* This will show an 'X' icon */}
          </StyledCloseButton>
          <StyledList>
            <ListItem button onClick={() => handleNavigate("/")}>
              <ListItemText primary="Back to Main" />
            </ListItem>
            {/* <ListItem button onClick={() => handleNavigate("/about-us")}>
              <ListItemText primary="About Us" />
            </ListItem> */}
            <ListItem button onClick={() => handleNavigate("/members")}>
              <ListItemText primary="Member" />
            </ListItem>
            <ListItem button onClick={() => handleNavigate("/contact-us")}>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </StyledList>
          <Typography variant="subtitle1" gutterBottom>
            | team.myswisss@gmail.com |
          </Typography>
          <Box>
            <IconButton>{/* Icon for Facebook */}</IconButton>
            <IconButton>{/* Icon for Instagram */}</IconButton>
            {/* ... Add more as needed */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Navbar;
