// FlippingCard.js
import React, { useState } from "react";
import { Box, CardMedia, Typography, Button, Modal } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { Helmet } from "react-helmet";
// import ChefDish from "../../../zurich-explore-backend/models/chefDish";

const FlipCard = styled(Box)(({ size }) => ({
  perspective: "1000px",
  width: "100%",
  margin: "16px",
  height: size === "large" ? "600px" : size === "medium" ? "550px" : "500px",
}));

const FlipCardInner = styled(Box)(({ theme, flipped }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
  transform: flipped ? "rotateY(180deg)" : "none",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "transparent",
  borderRadius: "10px",
}));

const FlipCardFront = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  boxSizing: "border-box",
  overflow: "hidden", // Ensure no content overflows
});

const FlipCardBack = styled(Box)({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  transform: "rotateY(180deg)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "16px",
  boxSizing: "border-box",
  overflow: "hidden", // Ensure no content overflows
});

const CardMediaStyled = styled(CardMedia)({
  height: "65%", // Adjusted height
  width: "100%",
  objectFit: "contain", // Ensure the entire image is visible
  marginBottom: "8px",
});

const underlineAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

const ReadMoreButton = styled(Button)(({ theme }) => ({
  marginTop: "8px",
  backgroundColor: "transparent",
  color: theme.palette.text.primary, // Use the color from the page theme
  textTransform: "none",
  padding: "0",
  minWidth: "auto",
  fontWeight: "bold",
  position: "relative",
  "&:hover": {
    backgroundColor: "transparent",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: "0",
      height: "2px",
      backgroundColor: theme.palette.text.primary,
      animation: `${underlineAnimation} 0.5s forwards`,
    },
  },
}));

const MyCreationButton = styled(Button)(({ theme }) => ({
  marginTop: "8px",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  textTransform: "none",
  padding: "0",
  minWidth: "auto",
  fontWeight: "bold",
  position: "relative",
  "&:hover": {
    backgroundColor: "transparent",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: "0",
      height: "2px",
      backgroundColor: theme.palette.text.primary,
      animation: `${underlineAnimation} 0.5s forwards`,
    },
  },
}));

const InstagramButton = styled(Button)(({ theme }) => ({
  marginTop: "8px",
  backgroundColor: "transparent",
  color: theme.palette.text.primary,
  textTransform: "none",
  padding: "0",
  minWidth: "auto",
  fontWeight: "bold",
  position: "relative",
  "&:hover": {
    backgroundColor: "transparent",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: "-2px",
      left: 0,
      width: "0",
      height: "2px",
      backgroundColor: theme.palette.text.primary,
      animation: `${underlineAnimation} 0.5s forwards`,
    },
  },
}));

const FlippingCard = ({ dish, size }) => {
  const [flipped, setFlipped] = useState(false);
  const [openChefBio, setOpenChefBio] = useState(false);
  const [openDishDetails, setOpenDishDetails] = useState(false);

  const handleReadMoreChefClick = (e) => {
    e.stopPropagation();
    setOpenChefBio(true);
  };

  const handleReadMoreDishClick = (e) => {
    e.stopPropagation();
    setOpenDishDetails(true);
  };

  const handleCloseChefBio = () => {
    setOpenChefBio(false);
  };

  const handleCloseDishDetails = () => {
    setOpenDishDetails(false);
  };

  const handleMyCreationClick = (e) => {
    e.stopPropagation();
    setFlipped(true);
  };

  return (
    <>
      <Helmet>
        <title>Meet Chef - Signature Creations</title>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Person",
            name: dish.chefId.name,
            image: dish.chefId.image,
            description: dish.chefId.bio,
          })}
        </script>
      </Helmet>

      <FlipCard size={size} onClick={() => setFlipped(!flipped)}>
        <FlipCardInner flipped={flipped}>
          <FlipCardFront onMouseEnter={() => setFlipped(false)}>
            {dish.chefId ? (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "8px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontWeight: 700,
                  }}
                >
                  {dish.chefId.name}
                </Typography>
                <CardMediaStyled
                  component="img"
                  image={dish.chefId.image}
                  alt={`Chef ${dish.chefId.name}`}
                  loading="lazy"
                />
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: "8px",
                    textAlign: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {dish.chefId.bio}
                </Typography>
                <ReadMoreButton onClick={handleReadMoreChefClick}>
                  Read More
                </ReadMoreButton>
                <MyCreationButton onClick={handleMyCreationClick}>
                  My Creation
                </MyCreationButton>
              </>
            ) : (
              <Typography variant="h6" sx={{ marginBottom: "8px" }}>
                Chef not available
              </Typography>
            )}
          </FlipCardFront>
          <FlipCardBack>
            <CardMediaStyled
              component="img"
              image={dish.image}
              alt={`Dish ${dish.name}`}
              loading="lazy"
            />
            {/* <Typography variant="h6">{dish.name}</Typography> */}
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                textAlign: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
              }}
            >
              {dish.description}
            </Typography>
            <InstagramButton
              href={dish.chefId.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Instagram
            </InstagramButton>
            <ReadMoreButton onClick={handleReadMoreDishClick}>
              Read More
            </ReadMoreButton>
          </FlipCardBack>
        </FlipCardInner>
      </FlipCard>

      {/* Modal for full chef bio */}
      <Modal
        open={openChefBio}
        onClose={handleCloseChefBio}
        aria-labelledby="modal-title-chef"
        aria-describedby="modal-description-chef"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography id="modal-title-chef" variant="h6" component="h2">
            {dish.chefId.name}
          </Typography>
          <Typography id="modal-description-chef" sx={{ mt: 2 }}>
            {dish.chefId.bio.split("\n").map((paragraph, index) => (
              <Typography paragraph key={index}>
                {paragraph}
              </Typography>
            ))}
          </Typography>
          <Button onClick={handleCloseChefBio} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>

      {/* Modal for full dish details */}
      <Modal
        open={openDishDetails}
        onClose={handleCloseDishDetails}
        aria-labelledby="modal-title-dish"
        aria-describedby="modal-description-dish"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "10px",
          }}
        >
          <Typography id="modal-title-dish" variant="h6" component="h2">
            {dish.name}
          </Typography>
          <Typography id="modal-description-dish" sx={{ mt: 2 }}>
            {dish.description.split("\n").map((paragraph, index) => (
              <Typography paragraph key={index}>
                {paragraph}
              </Typography>
            ))}
          </Typography>
          <Button onClick={handleCloseDishDetails} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default FlippingCard;
