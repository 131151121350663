// src/axiosInstance.js
import axios from "axios";

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL from environment variables
  headers: {
    "Content-Type": "application/json", // Default content type
  },
});

// Add a request interceptor to automatically include the auth token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken"); // Get the auth token from local storage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the Authorization header
    }
    return config; // Return the updated config
  },
  (error) => {
    return Promise.reject(error); // Return any request errors
  }
);

export default axiosInstance; // Export the Axios instance
